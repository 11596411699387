require(['jquery'], function($) {
	// Add tab index for accessibility
	$(function() {
	    var tabindex = 1;
	    $('input,select,button,a').each(function() {
	        if (this.type != "hidden") {
	            var $input = $(this);
	            $input.attr("tabindex", tabindex);
	            tabindex++;
	        }
	    });
	});
	// Take image from featured-item and make it a background picture 
	$('.home .featured-item').each(function() {
		let getfeatItemImg = $(this).find('img');
		let featItemImg = getfeatItemImg.attr('src');
		getfeatItemImg.remove();
		$(this).css('background-image','url(' + featItemImg + ')');
	});
	if ( $('.product-search-container').length ) {
	// Sidebar customization for pages that do not have child categories 
	  const sidebarWrapper = $('.sidebar-wrapper .mmenu-target-categories');
	  const catSidebar = $('.sidebar-category');
	  const catTitle = 'Categories: ' + $(".module-sub-categories .active.parent > a").text().trim();

	  const catSiblings = $(".module-sub-categories .active .page-item");
	  console.log("sidebar len"  + $('.sidebar-category').length);
	  if (catSiblings.length && $('.sidebar-category').length == 0) {
	          let siblingHtml = '';
	          $(catSiblings).each(function(){
	              const anchor = $(this).find('a');
	              const href = anchor.attr('href');
	              const title = anchor.text().trim();
	              // add check here to see if the current url is the same URL to be inserted. If so, then remove URL change color to cymot orange
	              console.log("TESTINGGG: " + $('.module-sub-categories .page-item .active').text().trim());
	              console.log("TESTINGGG: " + title);
	              if ( $('.module-sub-categories .page-item .active').text().trim() == title ) {
	              	var listItem = "<li style='color:#f09638;font-weight:700;padding:0px 10px !important;cursor:not-allowed;'>"+title+"</li>";
	              } else {
	              	var listItem = "<li><a href="+href+">"+title+"</a></li>";
	              }
				  siblingHtml += listItem;
	          });
	
	          $(sidebarWrapper).append('<div class="sidebar in-sidebar sidebar-category"><div class="block block-vertnav"><div class="block-title"><strong><span>'+catTitle+'</span></strong></div><div class="block-content"><ul class=" accordion accordion-style1 vertnav vertnav-side clearer">'+siblingHtml+'</ul></div></div></div>');
	  }
	}
	// Hide banners on home page
	if ($('.isPublic').length) {
		$('.b2c-featured-items').show();
		$('.b2b-featured-items').hide();
	} else {
		$('.b2b-featured-items').show();
		$('.b2c-featured-items').hide();
	}
	//The shopping cart icon is a bag on the login page for some reason?
	$('.i-cart').removeClass('i-cart').addClass('fa').addClass('fa-shopping-cart');
	//  On mobile, show filter drop down
		// Force scroll to top
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		document.body.scrollTop = 0; // For Safari
	// Is visible jquery function
	$.fn.isVisible = function() {
	    // Current distance from the top of the page
	    var windowScrollTopView = $(window).scrollTop();
	    // Current distance from the top of the page, plus the height of the window
	    var windowBottomView = windowScrollTopView + $(window).height();
	    // Element distance from top
	    var elemTop = $(this).offset().top;
	    // Element distance from top, plus the height of the element
	    var elemBottom = elemTop + 1;
	    return ((elemBottom <= windowBottomView) && (elemTop >= windowScrollTopView));
	}
	// Is sidebar long enough to justify floating to the bottom?
	function isSideBarLong() {
		var sidebar = $('.sidebar-wrapper').outerHeight();
		console.log("sidebar:" + sidebar);
		if (sidebar > 350) {
			return true;	
		} else {
			return false;	
		}
	}
	// Hack to ensure footer occupies all whitespace at the end of the page
	$(window).on("load", function() {
		const newh = $(window).outerHeight() - $('.header-container').outerHeight() - $('.main-container').outerHeight() - $('.footer-container').outerHeight();
		if (newh > 0) {
	    	$('.main-container').css('margin-bottom', newh +'px');
		}
	});
	// Change newsletter button
	if ($('.newsletter-button span').length > 0) {
		$('.newsletter-button span').html('Submit');
	}
	// Set the placeholder on the subscribe form input
	if ($('#news-letter-input_email').length > 0) {
    	$('#news-letter-input_email').attr('placeholder', 'Email Address');
    }
    var isScrolledIntoView = function(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    };

    /* floating header */
    var halfScreenSize = ($(document).width() * 0.5);
    $(window).scroll(function() {
        $this = $(this);
        var topSize = 120;
        if ($this.width() <= 960) {
            topSize = 36;
        }
        if ($this.scrollTop() >= topSize) {
            if (!$('#header').hasClass('floating')) {
                $('body').css('padding-top', $('.header-container').height());
                $('#header').addClass('floating');
                $('#header').slideDown('fast');
                //$('.item-min-cart').hide();
                //$('.item-user-links').hide();
                //$('.item-switch-customer').hide();
            }
        }
        // Here the header doesn't float
        if ($this.scrollTop() < topSize) {
            if ($('#header').hasClass('floating')) {
                $('body').attr('style', '');
                $('#header').removeClass('floating');
                $('#header').attr('style', '');
                //$('.item-min-cart').show();
                //$('.item-user-links').show();
                //$('.item-switch-customer').show();
            }
        }

        if ($('button.btn-cart').length > 0) {
            var isVisibleOnScreen = isScrolledIntoView('button.btn-cart');
            if (isVisibleOnScreen) {
                $('.add-cart-fixed').hide();
                $('.add-cart-fixed').width('50%');
            } else {
                if ($('.add-cart-fixed').width() === halfScreenSize) {
                    $('.add-cart-fixed').show().animate({
                        width: '100%'
                    });
                }
            }
        }
    });

    if ($("#mini-cart").length > 0) {
        $("#mini-cart .icon.i-cart").removeClass("icon i-cart").addClass("fa fa-shopping-cart");
    }

    $(".form-search .button.button-search").html("<i class='fa fa-search'></i>");

    if ($(".variant-option").length > 0) {
        if ($(".variant-option .swatches-con .swatch img").length > 0) {
            $(".variant-option .swatches-con").css('display', 'block');
        }
    }
	/* 161933000032810
    $(window).on('resize', onResize);
	
    $(document).ready(function() {
        onResize();
    });
    

    var onResize = function() {
        if ($(window).width() < 960) {
            $(".nav-search .form-search .button.button-search").on("click", function(e) {
                if (!$(".nav-search").hasClass("fly-out")) {
                    e.preventDefault();
                    $(".nav-search").addClass("fly-out");
                    $(".nav-search .form-search .input-text").focus();
                    $(".nav-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
                }
            });
        }
    };
    */

    $(document).on('click', '.nav-search .button-close', function(e) {
        e.preventDefault();
        $(".nav-search").removeClass("fly-out");
    });
});